import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');
require('./lib/jquery.flip.min');
require('./lib/countUp.min');
require('./lib/TweenMax.min');
require('./lib/slick.min');
require('./lib/ripple');
//require('./lib/animation.gsap.min');
 

 
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


// $(document).foundation();


$(document).foundation().ready( function() {


    // CALL INIT
    init();

    // call all custom functions
    function init() {
      heroEffect();
      photoFlip();
      statsCounter();
      reviewsCarousel();
      linkEffects();
      finalOfferActions();
     // instagramFeed();
    }


  /*=====  Hero Unit Fade Out  ======*/
  function heroEffect() { 

    $(window).scroll(function(){
        $(".hero").css("opacity", 1 - $(window).scrollTop() / 650);
        //element.addEventListener("touchmove", touchMove, false);
    });

  }  // end heroEffect(); 



  /*=====  Leadership Photo Flip  ======*/
  function photoFlip() { 

    $(".leadership-card-one").flip({
      axis: 'y',
      trigger: 'manual',
      reverse: true
    });

    $( "#toggle-one" ).click(function(e) {
      e.preventDefault();
      $(".leadership-card-one").flip('toggle');
    });

    $(".leadership-card-two").flip({
      axis: 'y',
      trigger: 'manual',
      reverse: true
    });

    $( "#toggle-two" ).click(function(e) {
      e.preventDefault();
      $(".leadership-card-two").flip('toggle');
    });

    $(".leadership-card-three").flip({
      axis: 'y',
      trigger: 'manual',
      reverse: true
    });

    $( "#toggle-three" ).click(function(e) {
      e.preventDefault();
      $(".leadership-card-three").flip('toggle');
    });

        // add 'hovered' class to buttons on hover 
    $('.profile > .button').click(function () {
      $(this).toggleClass('clicked');
    });


  } // end photoFlip();



  /*=====  Stats Counter Animation  ======*/
  function statsCounter() { 

    $('.counter').each(function() {
    var $this = $(this),
        countTo = $this.attr('data-count');

    $({ countNum: $this.text()}).animate({
      countNum: countTo
    },
    {
      duration: 4000,
      useGrouping : true, 
      separator : ',',
      decimal: ".",
      easing:'linear',
      step: function() {
        $this.text(Math.floor(this.countNum));
      },
      complete: function() {
        $this.text(this.countNum);
        //alert('finished');
      }

    });  
  });


  // // These are the options that I'm going to be using on each statistic
  // var options = {
  //   useEasing: true,
  //   useGrouping: true,
  //   separator: ",",
  //   decimal: "."
  // };

  // // Find all Statistics on page, put them inside a variable
  // var statistics = $(".statistic");

  // // For each Statistic we find, animate it
  // statistics.each(function(index) {
  //   // Find the value we want to animate (what lives inside the p tags)
  //   var value = $(statistics[index]).html();
  //   // Start animating
  //   var statisticAnimation = new CountUp(statistics[index], 0, value, 0, 5, options);
  //   statisticAnimation.start();
  // });


  } // end statsCounter();



  /*=====  Carousels  ======*/
  function reviewsCarousel() { 
    
     // one item carousel
    $('.cc-horiz-carousel').slick({
        dots: false,
        //dotsClass: 'custom-dots',
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true, 
        // appendArrows
        prevArrow: $('.cc-horiz-prev'),
        nextArrow: $('.cc-horiz-next')
    });

  } // end reviewsCarousel(); 




  /*=====  Link Effects  ======*/
  function linkEffects() { 
    
    // add 'hovered' class to buttons on hover 
    $('.button').hover(function () {
      $(this).toggleClass('hovered');
    });

    // any <a> element with a class of 'no-link' will have default action prevented
    $('a.no-link').click(function (e) {
      e.preventDefault();
    });

  } // end linkEffects(); 






function finalOfferActions(){
  
  // Create IE + others compatible event handler
  var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
  var eventer = window[eventMethod];
  var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
  
  // Listen to message from child window
  eventer(messageEvent, function (e) {
    
      try{

        if(typeof e.data != "undefined" && e.data != null){

          var compareData = String(e.data);

          // if sent message is watch submitted redirect user to thank you page
        if (compareData == "offer_submitted") {
          
            // edit this for correct location
            

            window.location = '/pages/sell-my-watch-final-offer-address-confirm-thank-you'; 
        }
        if (compareData == "redirect_homepage") {
            window.location = '/'; 
        }
        if (compareData == "et_scroll_to_top") {
            jQuery('html, body').scrollTop(jQuery('.rtip-iframe-container').position().top - jQuery('.main-top').height());
        }
        if (compareData.match(/^webform_height_changed/)) {
            var iframeHeight = compareData.split('#')[1];
            jQuery('.rtip-iframe-container').height(iframeHeight);
        }
      }
      }catch(e){
        console.log(e);
      }
      
  }, false);
};






  /*=====  Fancy FadeIn  ======*/

  // function animateInView() { 

 
  // } // end animateInView();




  /*=====  Instagram  ======*/
  // function instagramFeed() { 

  //   var accessToken = '181009920.1677ed0.a9122c0ef1f54aefb0b0581b8b7321de'; // use your own token
    
  //   $.getJSON('https://api.instagram.com/v1/users/self/media/recent/?access_token='+accessToken+'&callback=?',function (insta) {
  //     $.each(insta.data,function (photos,src) {
  //       if ( photos === 5 ) { return false; }
  //       $('<a class="post" href="#"> <img src=" '
  //         + this.images.standard_resolution.url + 
  //         '" /> '
  //         + this.likes.count +
  //         ' &hearts;</a>'
  //         ).appendTo('#instafeed');
  //     }); 
  //   });
  
  // } // end instagramFeed();

  /*=====  Instagram  ======*/
  // function instagramFeed() { 

  //   var userFeed = new Instafeed({
  //     get: 'user',
  //     userId: '181009920',
  //     clientId: '018e2262c1394bcfb7f459fb5e7e59ba',
  //     accessToken: '181009920.1677ed0.a9122c0ef1f54aefb0b0581b8b7321de',
  //     resolution: 'standard_resolution',
  //     template: '<a href="{{link}}" target="_blank" id="{{id}}"><img src="{{image}}" /><div class="likes"><i class="fas fa-heart"></i> {{likes}}</div></a>',
  //     sortBy: 'most-recent',
  //     limit: 4,
  //     links: false
  //   });
  //   userFeed.run();
  
  // } // end instagramFeed();




}); // end document ready


